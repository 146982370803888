import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import { graphql, useStaticQuery } from 'gatsby';

const useBasketQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            headerConfig {
                                basketEmptyUpsellLabel
                                basketNavigationLabel
                                basketEmptyContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                    ... on Button {
                                        component
                                        ...Button
                                    }
                                }
                                wishlistNavigationLabel
                                wishlistAuthenticatedEmptyContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                }
                                wishlistUnauthenticatedEmptyContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                }
                                recentlyViewedNavigationLabel
                                recentlyViewedEmptyContent {
                                    ... on Heading {
                                        component
                                        ...Heading
                                    }
                                    ... on Paragraph {
                                        component
                                        ...Paragraph
                                    }
                                    ... on Button {
                                        component
                                        ...Button
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const content = application.data.config.headerConfig || {};

    const transformContent = content => {
        const result: Array<object> = [];

        content.forEach(item => {
            if (item.component === 'heading') {
                result.push({
                    component: item.component,
                    text: item.text,
                    type: item.type,
                });
            }
            if (item.component === 'paragraph') {
                result.push({
                    component: item.component,
                    text: item.richText,
                });
            }
            if (item.component === 'button') {
                const transformedButton = transformButton(item);
                result.push({
                    component: item.component,
                    ...transformedButton,
                });
            }
        });

        return result;
    };

    const transformedContent = {
        basketEmptyContent: transformContent(content.basketEmptyContent),
        basketNavigationLabel: content.basketNavigationLabel,
        basketEmptyUpsellLabel: content.basketEmptyUpsellLabel,
        wishlistAuthenticatedEmptyContent: transformContent(content.wishlistAuthenticatedEmptyContent),
        wishlistUnauthenticatedEmptyContent: transformContent(content.wishlistUnauthenticatedEmptyContent),
        wishlistNavigationLabel: content.wishlistNavigationLabel,
        recentlyViewedNavigationLabel: content.recentlyViewedNavigationLabel,
        recentlyViewedEmptyContent: transformContent(content.recentlyViewedEmptyContent),
    };

    return {
        ...transformedContent,
    };
};

export default useBasketQuery;
